<template>
  <div class="container">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
      <left-list :leftIndex="'4'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          <span>科普知识</span>
        </div>
        <div class="left-list-item" @click="leftClick('0')">
          <span>知识分类</span>
        </div>
        <div class="left-list-item left-list-active" @click="leftClick('1')">
          <span>知识列表</span>
        </div>
      </div>
      <div class="right-next">
        <div class="title">
          <span>编辑知识详情</span>
        </div>

        <div class="mt-20">
          <div class="knowledge-from">
            <div class="from-item">
              <span>标题<span class="txt-red">*</span>：</span>
              <div class="from-item-input">
                <input type="text" v-model="fromList.title" />
              </div>
            </div>
            <div class="from-item">
              <span class="from-item-title"
                >摘要<span class="txt-red">*</span>：</span
              >
              <div class="from-item-textarea">
                <textarea placeholder="文章摘要" maxlength="50" v-model="fromList.abstract"></textarea>
              </div>
            </div>
            <div class="from-item">
              <span>所属分类<span class="txt-red">*</span>：</span>
              <div class="from-item-select">
                <el-select v-model="fromList.type" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="from-item">
              <span>分类图片<span class="txt-red">*</span>：</span>
              <div class="from-item-upload">
                <el-upload
                  ref="upload"
                  class="upload-demo"
                  :action="$store.state.httpUrl + 'edit/type'"
                  name="file"
                  :data="fromList"
                  list-type="picture"
                  :auto-upload="false"
                  :multiple="false"
                  :headers="uploadHeaders"
                  :http-request="uploadFile"
                  :limit="1"
                  :on-exceed="uploadExceed"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <span class="ml-50 txt-red">请上传正方形图片</span>
                </el-upload>
              </div>
            </div>
            <div class="from-item">
              <quill-editor
                class="editor"
                ref="myTextEditor"
                v-model="fromList.content"
                :options="editorOption"
                @change="onEditorChange($event)"
              >
                <!-- @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
                @ready="onEditorReady($event)" -->
              </quill-editor>
            </div>
            <div class="from-item">
              <el-button type="primary" @click="submitUpload">提交</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftList from "../../../components/leftList.vue";
import topHeader from "../../../components/header.vue";
export default {
  components: {
    leftList,
    topHeader,
  },
  inject: ["reload"],
  data() {
    return {
      isCollapse: false,
      options: [],
      fromList: {
        id: "",
        title: "",
        type: "",
        content: null,
        abstract:'',
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image"], // 链接、图片、视频
          ], //工具菜单栏配置
        },
        placeholder: "请填写详情内容", //提示
        readyOnly: false, //是否只读
        theme: "snow", //主题 snow/bubble
        syntax: true, //语法检测
      },
      uploadHeaders: {
        "Remember-Token": localStorage.getItem("rememberToken"),
      },
      formDate: "",
    };
  },
  created() {
    this.getList();
    this.fromList.id = this.$route.query.id;
    this.getInfo();
  },
  methods: {
    getInfo(){
      this.$get('get/article',{
        id:this.$route.query.id
      }).then(res =>{
        console.log(res);
        this.fromList.title = res.data.data.title;
        this.fromList.content = res.data.data.content;
        this.fromList.type = res.data.data.type;
        this.fromList.abstract = res.data.data.short_title;
      })
    },
    uploadFile(file) {
      this.formDate.append("file", file.file);
    },
    getList() {
      this.$get("get/type", {
        num: 999,
      }).then((res) => {
        this.options = res.data.data.data;
        console.log(res);
      });
    },
    uploadExceed() {
      this.$notify({
        title: "提示",
        message: "只可选择一张图片",
        type: "warning",
      });
    },
    submitUpload() {
      if (this.fromList.title == "") {
        this.$notify.error({
          title: "提示",
          message: "分类标题不可为空！",
        });
        return false;
      }
      if (this.fromList.abstract == "") {
        this.$notify.error({
          title: "提示",
          message: "文章摘要不可为空！",
        });
        return false;
      }
      if (this.fromList.type == "") {
        this.$notify.error({
          title: "提示",
          message: "所属分类不可为空！",
        });
        return false;
      }
      if (this.fromList.content == null) {
        this.$notify.error({
          title: "提示",
          message: "详情不可为空！",
        });
        return false;
      }
      this.formDate = new FormData();
      this.$refs.upload.submit();
      this.formDate.append("id", this.fromList.id);
      this.formDate.append("title", this.fromList.title);
      this.formDate.append("type", this.fromList.type);
      this.formDate.append("content", this.fromList.content);
      this.formDate.append("short_title", this.fromList.abstract);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      this.$axios
        .post("http://weixin.maibaobao.shop/api/edit/article", this.formDate, config)
        .then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "提示",
              message: "操作成功！",
              type: "success",
            });
            this.$router.push("/knowledgelist");
          } else {
            this.$notify.error({
              title: "提示",
              message: res.data.message,
            });
          }
          // console.log(res);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    // 失去焦点
    // onEditorBlur(editor) {
    // //   console.log(editor);
    // },
    // // 获得焦点
    // onEditorFocus(editor) {
    // //   console.log(editor);
    // },
    // // 开始
    // onEditorReady(editor) {
    // //   console.log(editor);
    // },
    // 值发生变化
    onEditorChange(editor) {
      this.content = editor.html;
      //   console.log(editor);
    },
    leftClick(index) {
      if (index == 0 && this.$route.path != "/knowledge") {
        this.$router.push("/knowledge");
      } else if (index == 1) {
        this.$router.push("/knowledgelist");
      } else {
        this.reload();
      }
    },
    menuitemClick(pushUrl) {
      // console.log(this.$route.path == pushUrl);
      if (this.$route.path == pushUrl) {
        this.reload();
      } else if (pushUrl) {
        this.$router.push(pushUrl);
      } else {
        this.$notify.error({
          title: "提示",
          message: "暂无页面",
        });
        this.reload();
      }
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>

<style lang="scss">
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;
  &::-webkit-scrollbar {
    display: none;
  }
  .knowledge-from {
    margin-top: 30px;
    padding-left: 150px;
    .from-item {
      margin-top: 50px;
      .ql-container {
        height: 200px;
      }
      .from-item-select {
        display: inline-block;
        margin-left: 30px;
      }
      .from-item-textarea {
        display: inline-block;
        vertical-align: top;
        margin-left: 30px;
        textarea {
          width:600px;
          height:150px;
          border:1px solid #C0C4CC;
          resize: none;
        }
      }
      .from-item-upload {
        vertical-align: top;
        display: inline-block;
        margin-left: 30px;
      }
      .from-item-input {
        display: inline-block;
        width: 200px;
        border-bottom: 1px solid #bbb5b5;
        margin-left: 30px;
        input {
          width: 190px;
          border: 0px;
          outline: none;
        }
      }
    }
  }
  .date-search {
    margin-top: 30px;
    .el-button {
      padding: 12px 55px;
    }
  }
  .left-list {
    width: 10%;
    height: 93vh;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    border-right: 20px solid #f2f2f2;
    display: inline-block;
    text-align: center;
    &::-webkit-scrollbar {
      display: none;
    }
    &-title {
      margin: 20px 0;
      font-size: 16px;
    }
    &-item {
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        background-color: #3291f8;
      }
    }
    &-active {
      color: #fff;
      background-color: #3291f8;
    }
  }
  .right-next {
    height: 93vh;
    width: 88.5%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 20px solid #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .title {
    color: #000;
    height: 10px;
    padding-left: 15px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;

    .left-icon {
      i {
        cursor: pointer;
        color: #3291f8;
        font-size: 30px;
      }

      .el-icon-refresh-right {
        margin-left: 30px;
      }
    }

    .right-user {
      font-size: 14px;

      span {
        vertical-align: middle;
        margin-right: 20px;
      }

      .colse-btn {
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid #999;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 50px;

        span {
          margin-right: 10px;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}

.el-menu {
  width: 100%;
  background-color: #20222a;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #20222a;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #000;
  background-color: #ecf5ff;
}

.el-menu-item {
  color: #fff;

  i {
    color: #3291f8;
  }
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
  color: #20222a;
}

.menu-h5 {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

.menu-icon {
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 30px 22px;
}
</style>
